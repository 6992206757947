html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}
html,
body,
#app {
  color: black;
  background-color: rgb(255, 253, 250);
  font-weight: 350;
  font-family: "Gill Sans", -apple-system, BlinkMacSystemFont, sans-serif;
  letter-spacing: 0.01rem;

  word-spacing: 0.02rem;
  position: relative;
}

input {
  border-radius: 6px !important;
  text-indent: 10px;
  margin-bottom: 6px;
  border: 1px solid rgba(50, 50, 93, 0.1) !important;
  max-height: 40px !important;
  font-size: 16px !important;
  width: 100% !important;
  background: white;
  box-sizing: border-box;
}

main {
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  background-size: cover;
}

.input-field input[type="text"]:focus + label {
  color: #000;
  margin-bottom: -10px !important;
}
button:focus {
  background-color: transparent;
}

.toast {
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  font-family: "Gill Sans", Arial, sans-serif;
}

.list-image {
  border-radius: 13px;
  min-height: 70px;
  min-width: 70px;
  max-height: 70px;
  max-width: 70px;
  padding: 10px;
  object-fit: cover;
}

.menu-button {
  width: 100px;
  height: 30px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 45px;
  cursor: pointer;
  border: 1px solid black;
}

.image-input {
  max-height: 100% !important;
}

.sort {
  border-radius: 0px 10px 10px 0px !important;
}
.type {
  border-radius: 10px 0px 0px 10px !important;
}

.browser-default {
  max-width: 150px;
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 10px;
  cursor: pointer;
  color: black;
  height: 45px;
  outline: none !important;
  padding-left: 10px;
  -webkit-appearance: none;
  background: transparent;
}

.yellow-button {
  width: 200px;
  height: 50px;
  background: rgba(255, 255, 100, 0.6);
  border: 3px solid black;
  outline: none !important;
  font-weight: bold;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  cursor: pointer;
  color: black;
  text-align: center;

  border-radius: 5px;
}

.yellow-button:hover {
  background: rgba(255, 245, 0, 1);
  box-shadow: 0px 0px 5px black;
}

input {
  border-bottom: 1px solid rgba(100, 100, 100, 0.2) !important;
  box-shadow: none !important;
  outline: none !important;
}

input[type="search"] {
  border-bottom: 1px solid rgba(100, 100, 100, 0) !important;
  box-shadow: none !important;
  outline: none !important;
}

.input-field input[type="search"]:focus {
  border-bottom: 1px solid rgba(0, 0, 0) !important;
  box-shadow: 0 1px 0 0 #000;
}

.artwork-image {
  max-width: 100%;
  max-height: 100%;
  cursor: alias;
}
.card-image {
  max-width: 100%;
  max-height: 100%;
}
.outline-button {
  width: 250px;
  height: 50px;
  background: transparent;
  border: 2px solid black;
  outline: none;
  font-weight: bold;

  margin-top: 30px;
  cursor: pointer;
  color: black;
}

.prefix {
  color: rgb(185, 187, 185);
}

.artwork-info-subContent {
  font-size: 12px;
  margin-left: 3px;
}

.card-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: right;
  right: 0px;
  text-align: left;
  padding: 15px;
  color: black;
}

.card-content:hover {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

.art-content {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: right;
  right: 0px;
  text-align: left;
  margin-left: 3px;
  padding: 10px;
  color: black;
  width: 100%;
}

.admin-image-add {
  height: 100px;
}

.card-container {
  margin: 120px 20px;
  max-width: 500px;
}

.artwork-info-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

.artwork-info-card {
  display: flex;
  justify-content: flex-end;
  max-width: 250px;
  min-width: 200px;
}

.art-content-container {
  display: flex;

  justify-content: space-between;
  margin-top: 30px;
  min-width: 150px;
  max-width: 700px;
}

.outline-button:hover {
  background: rgba(255, 245, 0, 1) !important;
  .transition: 0.5s;
}

.full-width {
  width: 100%;
}

.column {
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.right {
  margin-right: 20px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-image {
  width: 50%;
}

.iframe {
  width: 560px;
  height: 315px;
}

.collection {
  border: none;
  background: transparent;
  margin-top: 20px;
}

.list-item {
  display: flex;
}

.list-item:hover {
  background: rgb(243, 243, 243) !important;
}
.list-item:active {
  background: rgb(244, 244, 244) !important;
}
.about-self-image {
  float: left;
  margin: 20px;
  margin-left: 0px;
  margin-bottom: 10px;
  height: 300px;
}

.about-image-two {
  width: 300px;
  float: right;
  margin: 20px;
}
.type-box-cover:hover {
  background: none;
  text-shadow: 0px 0px 10px white;
  color: black;
}

.clear-icon-btn {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.clear-icon-btn:hover {
  transform: scale(1.1);
}

.hyperlink:hover {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.banner {
  background: rgba(255, 255, 100, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100vw;
  font-size: 16px;
  position: fixed;
  text-align: center;
  flex-direction: column;
  bottom: 0;
  z-index: 9999999;
}

.list-container {
  margin: 40px 5px;
  margin-top: 20px;
  padding: 5px;
  max-width: 600px;
  width: 100%;
}

.about-title {
  margin-bottom: 30px;
  text-decoration: underline;
}
.about-container {
  display: flex;
  padding: 20px;
  justify-content: center;

  flex-direction: column;
  font-size: 16px;
}

.artwork-info-title {
  font-weight: bold;
}

.grid {
  flex-wrap: wrap !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.admin-main-button {
  margin: 20px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid white;
  padding: 20px;
  width: 400px;
  background: rgba(200, 200, 200, 0.2);
  color: black !important;
}

.admin-main-button:hover {
  box-shadow: 0px 0px 2px rgb(100, 100, 100);
  border: 1px solid black;
  background: white;
}

.active-pag {
  background: rgba(255, 245, 0, 1);
  color: black;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.pag {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: 10px;

  color: black;
}

ul li {
  color: black;
}

.admin-modal {
  max-width: 600px;
  background: rgb(255, 252, 245);
  border-radius: 10px;
  padding: 50px;
  padding-top: 20px;
  max-height: 90%;
  margin-top: -50px;
}

.addToCartModal-content {
  text-align: left;
}

.addToCart-image {
  max-width: 400px;
  cursor: alias;
  width: 100%;
  border-radius: 5px;
}
.yellow-action-btn {
  background: rgb(245, 245, 0);
  font-family: "Gill Sans", Arial, sans-serif;
  color: #000;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yellow-action-btn:hover {
  filter: contrast(115%);
}
.yellow-action-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.action-btn {
  border: 3px solid black;
  border-radius: 5px;
  padding: 5px 15px;
  color: black;
  font-size: 14px;
  margin: 10px;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  justify-content: center;
  background: white;
  font-weight: bold;
  font-family: "Gill Sans", Verdana, Arial, Tahoma, Serif;
  cursor: pointer;
}

.action-btn:hover {
  background: black;
  color: white;
  transform: scale(1.1);
  transition: 0.3s;
}

.delete {
  border: 3px solid red !important;
  color: red;
}

.delete:hover {
  background: red;
  color: white !important;
}

.bottom-sheet {
  max-height: 100% !important;
  border-top: 10px solid rgb(250, 250, 100);
  box-shadow: 0px 0px 12px rgb(50, 62, 72) !important;

  background: rgb(250, 250, 250);
}

.addToCart-modal {
  border-radius: 10px;
  max-width: 400px;
  width: 95% !important;
  margin-top: -20px;
  padding: 10px;
  background: rgb(253, 250, 248);
  border: 2px solid rgba(255, 255, 0, 0.9);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9),
    0px 0px 5px rgba(0, 0, 0, 0.9) inset;
  max-height: 90% !important;
}

.stripe-modal {
  border-radius: 10px;
  padding: 10px;
  max-width: 500px;
  margin-top: -20px;
  max-height: 800px !important;
  border: 2px solid rgba(255, 255, 0, 0.9);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.9),
    0px 0px 5px rgba(0, 0, 0, 0.9) inset;
}

.disabled {
  opacity: 0.1;
  cursor: none;
}

.disabled:hover {
  transform: none;
  transition: none;
}

.btn {
  border-radius: 3px;
  width: 160px;
  height: 45px;
  cursor: pointer;
  font-weight: bold;
  margin: 20px auto;
  letter-spacing: 0.1em;
  font-size: 12px;
}

.item-pag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  height: 20px;
  width: 20px;
  color: black !important;
  font-size: 18px;
}

.delete {
  border: 2px solid red;
}

.delete:hover {
  background: red;
  color: black;
}

/* iphone 5/se */
@media only screen and (max-width: 350px) {
  .type-image {
    width: 100px;
    height: 100px;
  }
}

/* iphone 6/7/8/X */
@media only screen and (max-width: 400px) {
  .banner {
    height: 60px;
    font-size: 13px;
  }
  .browser-default {
    max-width: 120px;
  }
}

@media only screen and (max-width: 500px) {
  .iframe {
    width: 200px;
    height: 200px;
  }

  .carousel-image {
    width: 200px;
  }
  .stripe-modal {
    width: 95vw;
    padding: 5px;
  }
}
/* iphone 6/7/8/X */
@media only screen and (max-width: 600px) {
  .about-image-two {
    width: 100%;
    margin: 20px 0px;
  }
  .type-image {
    width: 140px;
    height: 120px;
  }

  .btn {
    border-radius: 3px;
    width: 100px;
    height: 35px;
    letter-spacing: 0.05em;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .type-card {
    margin: 10px;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 0.05em;
  }
  .stripe-modal {
    width: 95vw;
    padding: 5px;
  }
}
@media only screen and (max-width: 700px) {
  .iframe {
    width: 337px;
    height: 189px;
  }
  .about-self-image {
    height: 200px;
  }

  .admin-modal {
    padding: 30px;
  }
}

/* ipad */
@media only screen and (max-width: 800px) {
  .about-container {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: 200px;
  }
}
